import React, { useState } from "react";
import Login from "./components/firebase/login";
import Analytics from "./components/firebase/analytics";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      {!isLoggedIn ? (
        <Login onLogin={() => setIsLoggedIn(true)} />
      ) : (
        <Analytics />
      )}
    </>
  );
}

export default App;
