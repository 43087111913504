// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import Authentication

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDkn3UTpeObplcgqO0-9LoshDbTTbVUf-I",
    authDomain: "portfolio-4e1f0.firebaseapp.com",
    projectId: "portfolio-4e1f0",
    storageBucket: "portfolio-4e1f0.appspot.com",
    messagingSenderId: "244114111837",
    appId: "1:244114111837:web:a391ef3ff36836dba1fcb4",
    measurementId: "G-S61VZ1N2TF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Comment out the unused analytics initialization
// const analytics = getAnalytics(app);

// Initialize Firebase Auth and Export It
export const auth = getAuth(app);
