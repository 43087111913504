/* global google */
import React, { useEffect } from "react";
import "./Analytics.css"; // Import external CSS for better styling

const Analytics = () => {
    useEffect(() => {
        const loadGIS = () => {
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            script.defer = true;
            script.onload = initializeAnalytics;
            script.onerror = () => console.error("Failed to load Google Identity Services script");
            document.head.appendChild(script);
        };

        const initializeAnalytics = () => {
            console.log("Google Identity Services script loaded.");

            google.accounts.id.initialize({
                client_id: "244114111837-apjsbbv890k8sabg7tv8v0smdtgm2i1m.apps.googleusercontent.com",
                callback: handleCredentialResponse,
            });

            google.accounts.id.prompt();
        };

        const handleCredentialResponse = (response) => {
            console.log("Google Credential Response:", response);
            const accessToken = response.credential;
            loadAnalyticsAPI(accessToken);
        };

        const loadAnalyticsAPI = (accessToken) => {
            const script = document.createElement("script");
            script.src = "https://apis.google.com/js/platform.js";
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (window.gapi) {
                    initializeEmbedAPI(accessToken);
                }
            };
            script.onerror = () => console.error("Failed to load Google Analytics Embed API script");
            document.head.appendChild(script);
        };

        const initializeEmbedAPI = (accessToken) => {
            window.gapi.load("analytics", () => {
                console.log("Initializing Analytics Embed API...");

                window.gapi.analytics.auth.authorize({
                    serverAuth: { access_token: accessToken },
                });

                const viewSelector = new window.gapi.analytics.ViewSelector({
                    container: "view-selector",
                });
                viewSelector.execute();

                viewSelector.on("change", (ids) => {
                    const dataChart = new window.gapi.analytics.googleCharts.DataChart({
                        query: {
                            ids,
                            metrics: "ga:sessions",
                            dimensions: "ga:date",
                            startDate: "7daysAgo",
                            endDate: "today",
                        },
                        chart: {
                            type: "LINE",
                            container: "chart-container",
                        },
                    });
                    dataChart.execute();
                });
            });
        };

        loadGIS();
    }, []);

    return (
        <div className="analytics-container">
            <h2 className="analytics-title">Google Analytics Dashboard</h2>
            <div id="embed-api-auth-container" className="auth-container"></div>
            <div id="view-selector" className="view-selector"></div>
            <div id="chart-container" className="chart-container"></div>
        </div>
    );
};

export default Analytics;
